<template>
  <b-overlay
    :show="isLoading"
    :opacity="0.6"
    spinner-variant="primary"
    spinner-type="grow"
    rounded="sm"
  >
    <b-card-code>
      <validation-observer ref="addDocument">
        <b-form>
          <b-row>
            <b-col cols="12">
              <b-form-group class="form_custom">
                <validation-provider
                  #default="{ errors }"
                  name="Tiêu đề"
                  vid="name"
                  rules="required"
                  mode="passive"
                >
                  <label
                    for="name"
                    :class="errors.length > 0 ? 'text-danger' : ''"
                  >Tiêu đề <span class="text-danger">(*)</span></label>
                  <b-form-input
                    v-model="frm.name"
                    type="text"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Tiêu đề"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group class="form_custom">
                <validation-provider
                  #default="{ errors }"
                  name="Nội dung"
                  vid="description"
                  rules="required"
                  mode="passive"
                >
                  <label
                    for="description"
                    :class="errors.length > 0 ? 'text-danger' : ''"
                  >Nội dung <span class="text-danger">(*)</span></label>
                  <quill-editor
                    v-model="frm.description"
                    :options="snowOption"
                    :placeholder="'Nhập nội dung tài liệu ...'"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-button
                variant="danger"
                class="float-right mb-20 btn-sm"
                @click="validationForm"
              >
                Lưu tài liệu
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
    <b-skeleton-wrapper
      v-if="isLoading"
      :loading="true"
    >
      <template #loading>
        <b-card>
          <b-skeleton width="85%" />
          <b-skeleton width="55%" />
          <b-skeleton width="70%" />
        </b-card>
      </template>
    </b-skeleton-wrapper>
    <b-card-code v-else>
      <table
        class="table b-table"
        style="margin-top: 10px"
      >
        <thead>
          <tr>
            <th
              scope="col"
              width="10"
            >
              STT
            </th>
            <th scope="col">
              Tiêu đề
            </th>
            <th scope="col">
              Nội dung
            </th>
            <th
              scope="col"
              width="100"
            >
              Tùy chọn
            </th>
          </tr>
        </thead>
        <draggable
          v-if="rows.length"
          v-model="rows"
          tag="tbody"
          class="cursor-move"
          @end="onEnd"
        >
          <tr
            v-for="(item, index) in rows"
            :key="item.id"
          >
            <td>{{ index + 1 }}</td>
            <td><strong>{{ item.name }}</strong></td>
            <td>
              <span v-html="item.description" />
            </td>
            <td>
              <b-dropdown
                class="custom-position-dropdown"
                variant="flat-sencondary"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreHorizontalIcon"
                    size="16"
                    class="align-middle"
                  />
                </template>
                <b-dropdown-item @click="handleEdit(item)">
                  Sửa
                </b-dropdown-item>
                <b-dropdown-item
                  @click="deleteDocument(item.id)"
                >
                  Xoá
                </b-dropdown-item>
              </b-dropdown>
            </td>
          </tr>
        </draggable>
        <tr v-else>
          <td colspan="3">
            <p class="text-center">
              {{ $t('no_data') }}
            </p>
          </td>
        </tr>
      </table>
    </b-card-code>
    <b-modal
      id="modal-edit"
      v-model="modalShow"
      cancel-variant="outline-secondary"
      ok-title="Lưu thông tin"
      cancel-title="Huỷ"
      centered
      title="Cập nhật tài liệu"
      size="lg"
      :no-close-on-esc="false"
      :no-close-on-backdrop="false"
      @ok="updateDocument"
    >
      <validation-observer ref="updateRules">
        <b-form>
          <b-row>
            <b-col cols="12">
              <b-form-group class="form_custom">
                <validation-provider
                  #default="{ errors }"
                  name="tiêu đề"
                  vid="name"
                  rules="required"
                >
                  <label>Tiêu đề</label>
                  <b-form-input
                    id="name"
                    v-model="frmEdit.name"
                    type="text"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Tiêu đề"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group class="form_custom">
                <validation-provider
                  #default="{ errors }"
                  name="nội dung"
                  vid="description"
                  rules="required"
                >
                  <label>Nội dung</label>
                  <quill-editor
                    v-model="frmEdit.description"
                    :options="snowOption"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <template #modal-footer="{ ok, cancel, hide }">
        <!-- Button with custom close trigger value -->
        <b-button
          variant="outline-secondary"
          @click="hide('forget')"
        >
          Huỷ
        </b-button>
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button
          variant="danger"
          :disabled="isLoading"
          @click.prevent="ok()"
        >
          Lưu thông tin
        </b-button>
      </template>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BRow, BSkeleton, BCard, BSkeletonWrapper, BCol, BFormInput, BOverlay, BFormGroup, BButton, BForm, BFormTextarea, BDropdown, BDropdownItem, BCardText,
} from 'bootstrap-vue'
import {
  ValidationProvider, ValidationObserver, localize,
} from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { quillEditor } from 'vue-quill-editor'
import BCardCode from '@core/components/b-card-code'
import draggable from 'vuedraggable'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSkeleton,
    BCard,
    BSkeletonWrapper,
    BRow,
    draggable,
    BCol,
    BFormInput,
    quillEditor,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormGroup,
    BButton,
    BForm,
    BFormTextarea,
    BDropdown,
    BDropdownItem,
    BCardText,
    BCardCode,
  },
  directives: {
    Ripple,
  },
  props: {
    courseId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      modalShow: false,
      snowOption: {
        theme: 'snow',
        placeholder: 'Nhập nhóm câu hỏi',
      },
      frm: {
        name: '',
        description: '',
      },
      frmEdit: {
        id: '',
        name: '',
        order: '',
        description: '',
      },
      rows: [],
    }
  },
  created() {
    this.loadItems()
  },
  methods: {
    showToast(variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Thông báo',
          icon: 'BellIcon',
          text,
          variant,
        },
      })
    },
    loadItems() {
      const params = {
        course_id: this.courseId,
      }

      this.isLoading = false
      this.$http.get('/documents', {
        params,
      })
        .then(res => {
          this.rows = res.data.data
        }).catch()
        .finally(() => {
          this.isLoading = false
        })
    },
    validationForm() {
      this.$refs.addDocument.validate().then(success => {
        if (success) {
          this.isLoading = true
          this.$http({
            method: 'post',
            url: '/documents',
            data: {
              name: this.frm.name,
              description: this.frm.description,
              course_id: this.courseId,
            },
          })

          // eslint-disable-next-line no-unused-vars
            .then(res => {
              this.showToast('success', 'Tạo mới thành công')

              this.loadItems()
              this.frm.name = ''
              this.frm.description = ''
            })
          // eslint-disable-next-line no-unused-vars
            .catch(e => {
              this.showToast('danger', 'Tạo mới thất bại')
            }).finally(() => {
              this.isLoading = false
            })
        }
      })
    },
    deleteDocument(index) {
      this.$swal({
        title: this.$t('message.Confirm_delete'),
        text: false,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http({
            method: 'delete',
            url: '/documents',
            data: {
              id: index,
            },
          })
          // eslint-disable-next-line no-unused-vars
            .then(res => {
              this.showToast('success', 'Xóa tài liệu thành công')

              this.loadItems()
            })
          // eslint-disable-next-line no-unused-vars
            .catch(e => {
              this.showToast('danger', 'Xóa tài liệu thất bại')
            }).finally(() => {
              this.isLoading = false
            })
        }
      })
    },
    handleEdit(item) {
      this.modalShow = true
      this.frmEdit.id = item.id
      this.frmEdit.name = item.name
      this.frmEdit.description = item.description
      this.frmEdit.order = item.order
    },
    updateDocument() {
      this.$refs.updateRules.validate().then(success => {
        if (success) {
          this.isLoading = true
          this.$http({
            method: 'put',
            url: `/documents/${this.frmEdit.id}`,
            data: {
              name: this.frmEdit.name,
              description: this.frmEdit.description,
            },
          })

          // eslint-disable-next-line no-unused-vars
            .then(res => {
              this.showToast('success', 'Cập nhật thành công')

              this.loadItems()
            })
          // eslint-disable-next-line no-unused-vars
            .catch(e => {
              this.showToast('danger', 'Cập nhật thất bại')
            }).finally(() => {
              this.isLoading = false
            })
        }
      })
    },
    onEnd(value) {
      if (value.oldIndex === value.newIndex) return
      const newOrder = this.rows[value.newIndex].order
      const oldOrder = this.rows[value.oldIndex].order
      if (newOrder === oldOrder) return
      if (value.newIndex < value.oldIndex) {
        this.changeOrder(newOrder, this.rows[value.newIndex + 1].order)
      } else {
        this.changeOrder(newOrder, this.rows[value.newIndex - 1].order)
      }
    },
    changeOrder(oldOrder, newOrder) {
      this.isLoading = true
      this.$http.put('/documents', { oldOrder, newOrder, course_id: this.courseId })
      // eslint-disable-next-line no-unused-vars
        .then(res => {
          this.loadItems()
          this.$bvToast.toast(this.$t('message.update_success'), {
            title: this.$t('message.notify'),
            icon: 'BellIcon',
            variant: 'success',
            solid: false,
          })
          // eslint-disable-next-line no-unused-vars
        }).catch(e => {
          this.$bvToast.toast(this.$t('message.update_err'), {
            title: this.$t('message.notify'),
            icon: 'BellIcon',
            variant: 'danger',
            solid: false,
          })
        }).finally(() => {
          this.isLoading = false
        })
    },

  },
}
</script>
