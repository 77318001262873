<template>
  <b-tabs
    v-model="tabIndex"
    class="tabs_custom"
  >
    <b-tab
      title="Thông tin chung"
      @click="rememberTab('#')"
    >
      <validation-observer ref="addCourse">
        <b-form class="form_custom">
          <b-row>
            <b-col cols="8">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Tên khoá học"
                  vid="name"
                  rules="required"
                >
                  <label
                    for="name"
                    :class="errors.length > 0 ? 'text-danger': null"
                  >{{ $t('name_q') }} <span class="text-danger">(*)</span></label>
                  <b-form-input
                    id="name"
                    v-model="frm.name"
                    type="text"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Tên khoá học"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-row>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Cấp độ"
                    vid="level"
                    rules="required"
                  >
                    <b-form-group class="custom_input_tag">
                      <label
                        for="level"
                        :class="errors.length > 0 ? 'text-danger': null"
                      >{{ $t('level') }} <span class="text-danger">(*)</span></label>
                      <v-select
                        id="level"
                        v-model="frm.level"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        :options="levelData"
                        :reduce="label => label.id"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Chọn cấp độ"
                      />
                    </b-form-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>

                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="video giới thiệu"
                      vid="video"
                      rules="url"
                    >
                      <label for="video">{{ $t('link_video_intro') }}</label>
                      <b-form-input
                        id="video"
                        v-model="frm.link_video_intro"
                        type="text"
                        :state="errors.length > 0 ? false:null"
                        placeholder="URL video giới thiệu"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

              </b-row>
              <b-form-group>
                <label for="description">{{ $t('description') }}</label>
                <b-form-textarea
                  id="description"
                  v-model="frm.description"
                  placeholder="Mô tả"
                  rows="5"
                />
              </b-form-group>
              <b-row>
                <b-col md="6">
                  <label>{{ $t('status') }} <span class="text-danger">(*)</span></label>
                  <b-form-group class="inline-checkbox">
                    <b-form-checkbox
                      id="status"
                      v-model="frm.status"
                      class="custom-control-danger"
                      name="check-button"
                      switch
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <label>Khóa bài học </label>
                  <b-form-group class="inline-checkbox">
                    <b-form-checkbox
                      id="is_lock_lesson"
                      v-model="frm.is_lock_lesson"
                      class="custom-control-danger"
                      name="check-button"
                      switch
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="4">
              <div class="group_file_upload">
                <b-card-text
                  v-if="frm.image"
                  class="block-img"
                >
                  <b-img
                    class="mb-1 mb-sm-0"
                    height="120"
                    :src="frm.image"
                    alt="Right image"
                  />
                </b-card-text>
              </div>
              <upload-media
                style="margin-left: 38%"
                :image="frm.image"
                :audio="frm.audio"
                :choose="1"
                @uploadMedia="uploadMedia"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-button
                variant="danger"
                type="submit"
                class="btn-sm"
                style="float: right; margin-bottom: 10px"
                @click.prevent="submitCourse"
              >
                {{ $t('save_info') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-tab>
    <b-tab
      id="thongtinbaihoc"
      title="Thông tin bài học"
      :disabled="!frm.id"
      @click="rememberTab('#thongtinbaihoc')"
    >
      <lesson
        v-if="tabIndex === 1"
        :course-id="parseInt(frm.id)"
        :course-name="frm.name"
      />
    </b-tab>
    <b-tab
      id="tailieutonghop"
      title="Tài liệu tổng hợp"
      :disabled="!frm.id"
      @click="rememberTab('#tailieutonghop')"
    >
      <document
        v-if="tabIndex === 2"
        :course-id="parseInt(frm.id)"
      />
    </b-tab>
  </b-tabs>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BRow, BCol, BFormGroup, BFormInput, BButton, BForm, BFormCheckbox, BOverlay, BTab, BCardText, BTabs, BFormFile, BFormTextarea, BImg,
} from 'bootstrap-vue'
import {
  required, image, url, size,
} from '@validations'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import config from '@/config'
import uploadMedia from '@/views/library/UploadMedia'
import lesson from '@/views/courses/Lesson'
import document from '@/views/courses/Document'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    BButton,
    ValidationObserver,
    BForm,
    lesson,
    document,
    BFormCheckbox,
    BOverlay,
    BTab,
    BCardText,
    BTabs,
    BFormFile,
    vSelect,
    BFormTextarea,
    BImg,
    uploadMedia,
  },
  data() {
    return {
      isLoading: false,
      tabIndex: 0,
      frm: {
        id: this.$route.params.id,
        name: '',
        description: null,
        link_video_intro: null,
        status: true,
        file_id: null,
        teacher_id: [],
        level: null,
        // eslint-disable-next-line global-require
        image: require('@/assets/images/default.jpg'),
        audio: null,
        is_lock_lesson: false,
      },
      // eslint-disable-next-line global-require
      imgUrl: null,
      options: [
        { text: 'Hoạt động', value: 1, disabled: false },
        { text: 'Không hoạt động', value: 0, disabled: false },
      ],
      required,
      image,
      url,
      size,
      teacherData: [
        { id: 1, name: 'Daopd' },
        { id: 2, name: 'Daobka' },
      ],
      levelData: [],
      typeData: config.course.type,
      endPoint: process.env.VUE_APP_API_ENDPOINT,
    }
  },
  watch: {
    tabIndex: {
      handler(value) {
        // console.log('value', value)
      },
    },
  },
  created() {
    if (this.tabIndex === 0) {
      if (this.frm.id) this.loadItem()
      this.loadLevels()
    }
    if (this.frm.id && this.$route.hash === '#thongtinbaihoc') {
      this.tabIndex = 1
    }
    if (this.frm.id && this.$route.hash === '#tailieutonghop') {
      this.tabIndex = 2
    }
  },
  methods: {
    loadItem() {
      this.isLoading = true
      this.$http.get(`/courses/${this.frm.id}`)
        .then(res => {
          const { data } = res
          this.frm.id = data.id
          this.frm.name = data.name
          this.frm.status = data.status === 1
          this.frm.level = data.level_id
          this.frm.link_video_intro = data.link_video_intro
          this.frm.description = data.description
          this.frm.is_lock_lesson = data.is_lock_lesson === 1
          if (data.teachers.length) {
            data.teachers.map(v => {
              this.frm.teacher_id.push(v.id)
            })
          }
          if (data.media) {
            this.frm.file_id = data.media.id
            this.frm.image = `${this.endPoint}/storage${data.media.url}`
          }
        }).catch()
        .finally(() => {
          this.isLoading = false
        })
    },
    onFileChange(e) {
      const file = e.target.files[0]
      this.imgUrl = URL.createObjectURL(file)
    },
    loadLevels() {
      this.isLoading = true
      this.$http.get('levels')
        .then(res => {
          this.levelData = res.data.data
        }).catch()
        .finally(() => {
          this.isLoading = false
        })
    },
    submitCourse() {
      this.$refs.addCourse.validate().then(success => {
        if (success) {
          this.isLoading = true
          const formData = new FormData()

          formData.append('name', this.frm.name)
          if (this.frm.teacher_id.length) {
            formData.append('teacher_id', JSON.stringify(this.frm.teacher_id))
          }
          if (this.frm.description) formData.append('description', this.frm.description)
          if (this.frm.file_id) formData.append('files_id', this.frm.file_id)
          if (this.frm.level) formData.append('level_id', this.frm.level)
          if (this.frm.link_video_intro) formData.append('link_video_intro', this.frm.link_video_intro)
          formData.append('status', this.frm.status ? 1 : 0)
          formData.append('is_lock_lesson', this.frm.is_lock_lesson ? 1 : 0)
          if (this.frm.id) formData.append('_method', 'PUT')
          const url = this.frm.id ? `/courses/${this.frm.id}` : '/courses'
          this.$http({
            method: 'post',
            url,
            data: formData,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })

          // eslint-disable-next-line no-unused-vars
            .then(res => {
              this.$bvToast.toast(this.$t('message.update_success'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'success',
                solid: false,
              })

              if (!this.frm.id) {
                this.frm.id = res.data.id
              }
            })
          // eslint-disable-next-line no-unused-vars
            .catch(error => {
              let errorMsg = {}
              if (error.response.status === 422) {
                errorMsg = error.response.data
              }
              this.$refs.addCourse.setErrors(errorMsg)
              this.$bvToast.toast(this.$t('message.update_err'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'danger',
                solid: false,
              })
            }).finally(() => {
              this.isLoading = false
            })
        }
      })
    },
    rememberTab(tabId) {
      window.location.href = tabId
    },
    uploadMedia(media) {
      if (media.image) {
        this.frm.file_id = media.image.id
        this.frm.image = `${this.endPoint}/storage${media.image.url}`
      }
    },
  },
}
</script>

<style lang="scss" scoped>
table.permission_list th, table.permission_list td {
  text-align: center;
}

table.permission_list tr:nth-child(odd) {
  background-color: #f8f8f8
}
.group_file_upload {
  min-height: 218px;
  margin-top: 60px;
  border: 1px solid #eaeaea;
  display: flex;
  justify-content: center;
  align-items: center;
}
.vs__selected {
  color: #fff !important;
}
</style>
