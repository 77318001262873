<template>
  <b-overlay
    :show="isLoading"
    :opacity="0.6"
    rounded="sm"
  >
    <b-list-group class="list-group-child-exercise-course pl-3">
      <draggable
        v-model="rows"
        tag=""
        @end="value => onEnd(value)"
      >
        <b-list-group-item
          v-for="(item, index) in rows"
          :key="index"
          class="align-items-center"
        >
          <div class="item_content_row__exercise">
            <b-row>
              <b-col sm="9">
                <router-link
                  v-if="item.type === 1"
                  class="text-black"
                  :to="{ name: 'exam-edit', params: { id: item.id, name: item.name }}"
                >
                  <b-card-text>
                    <feather-icon
                      icon="BookIcon"
                      class="mr-1"
                    />
                    {{ item.name }}
                  </b-card-text>
                </router-link>
                <span
                  v-else
                  class="name_ex_video"
                  @click="editVideo(item)"
                ><feather-icon
                   icon="VideoIcon"
                   class="mr-1"
                 />
                  {{ item.name }}</span>
              </b-col>
              <b-col sm="3">
                <div class="control_button_row">
                  <b-button
                    v-if="item.type === 1"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-secondary"
                    class="btn-icon rounded-circle float-right"
                    style="margin-left: 5px"
                    @click="deleteLesson(item.id, index)"
                  >
                    <feather-icon icon="Trash2Icon" />
                  </b-button>
                  <template v-else>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-secondary"
                      class="btn-icon rounded-circle float-right"
                      style="margin-left: 5px"
                      @click="remove(item.id, index)"
                    >
                      <feather-icon icon="Trash2Icon" />
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-secondary"
                      class="btn-icon rounded-circle float-right"
                      style="margin-left: 5px"
                      @click="editVideo(item)"
                    >
                      <feather-icon icon="Edit2Icon" />
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-secondary"
                      class="btn-icon rounded-circle float-right"
                      style="margin-left: 5px"
                      @click="loadChapterByLesson(item.id)"
                    >
                      <feather-icon icon="FlagIcon" />
                    </b-button>
                  </template>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-list-group-item>
      </draggable>
    </b-list-group>

    <b-modal
      id="modal-edit"
      v-model="modalShow"
      cancel-variant="outline-secondary"
      ok-title="Lưu thông tin"
      cancel-title="Huỷ"
      centered
      :title="modalName"
      size="xl"
      :no-close-on-esc="false"
      :no-close-on-backdrop="false"
      @ok="handleOk"
    >
      <validation-observer ref="formRules">
        <b-form>
          <b-row>
            <b-col cols="12">
              <b-form-group class="form_custom">
                <validation-provider
                  #default="{ errors }"
                  :name="$t('name_q')"
                  vid="name"
                  rules="required"
                >
                  <label
                    for="name"
                    :class="errors.length > 0 ? 'text-danger' : ''"
                  >{{ $t('name_q') }} <span class="text-danger">(*)</span></label>
                  <b-form-input
                    id="name"
                    v-model="frm.name"
                    type="text"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Nhập tên video bài học"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <label for="description">{{ $t('description') }}</label>
                <b-form-textarea
                  id="description"
                  v-model="frm.description"
                  placeholder="Nhập mô tả"
                  rows="1"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="8">
              <b-form-group class="form_custom">
                <validation-provider
                  #default="{ errors }"
                  name="Link"
                  vid="link"
                  rules="required"
                >
                  <label
                    for="link"
                    :class="errors.length > 0 ? 'text-danger' : ''"
                  >File video <span class="text-danger">(*)</span></label>
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      {{ mediaDomain }}
                    </b-input-group-prepend>
                    <b-form-input
                      id="link"
                      v-model="frm.link"
                      type="text"
                      :state="errors.length > 0 ? false:null"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <label for="document_link">{{ $t('document') }}</label>
              <b-row>
                <b-col cols="4">
                  <b-form-group class=" custom_select_group">
                    <v-select
                      v-model="frm.document.type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="label"
                      :options="option"
                      :reduce="label => label.value"
                      placeholder="Chọn thể loại"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="7">
                  <b-form-group class="form_custom">

                    <validation-provider
                      v-if="frm.document.type === 'link'"
                      #default="{ errors }"
                      name="document_link"
                      vid="document_link"
                      rules="required|url: {require_protocol: true }"
                    >
                      <b-form-input
                        id="document_link"
                        v-model="frm.document.link"
                        type="text"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Nhập đường dẫn tài liệu"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>

                    <validation-provider
                      v-if="frm.document.type === 'upload'"
                      name="document_file"
                      vid="document_file"
                    >
                      <b-card-text
                        v-if="frm.document_file"
                        class="text-center"
                        style="color: #FF7F00;overflow-wrap: break-word;"
                      >
                        {{ frm.document_file.name }}
                      </b-card-text>
                      <upload-media
                        v-else
                        class="library-document"
                        :choose="2"
                        @uploadMedia="uploadMedia"
                      />
                    </validation-provider>

                  </b-form-group>
                </b-col>
                <b-col cols="1">
                  <feather-icon
                    v-if="frm.document.link || frm.document.file_id"
                    icon="Trash2Icon"
                    class="cursor-pointer"
                    @click="removeDocument"
                  />
                </b-col>
              </b-row>

              <b-form-group class="form_custom">
                <label for="is_trial_lesson">{{ $t('study_trial') }}</label>
                <b-form-checkbox
                  id="is_trial_lesson"
                  v-model="frm.is_trial"
                  class="custom-control-danger"
                  name="check-button"
                  switch
                />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <validation-provider
                #default="{ errors }"
                :name="$t('image')"
                vid="image"
              >
                <label
                  :class="errors.length > 0 ? 'text-danger': null"
                >{{ $t('image') }}<span class="text-danger">(*)</span> <small class="text-danger">{{ errors[0] }}</small></label>
                <div class="group_file_upload">
                  <b-card-text
                    v-if="frm.image"
                    class="block-img"
                  >
                    <b-img
                      class="mb-1 mb-sm-0"
                      height="120"
                      :src="frm.image"
                      alt="Right image"
                    />
                  </b-card-text>
                </div>
                <upload-media
                  style="margin-left: 38%"
                  :choose="1"
                  @uploadMedia="uploadMedia"
                />
              </validation-provider>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <template #modal-footer="{ ok, cancel, hide }">
        <!-- Button with custom close trigger value -->
        <b-button
          variant="outline-secondary"
          @click="hide('forget')"
        >
          Huỷ
        </b-button>
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button
          variant="danger"
          :disabled="isLoading"
          @click.prevent="ok()"
        >
          {{ $t('save_info') }}
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="modal-edit"
      v-model="modalShowExercise"
      cancel-variant="outline-secondary"
      centered
      title="Thư viện bài tập"
      :no-close-on-esc="false"
      :no-close-on-backdrop="false"
      size="xl"
      hide-footer
    >
      <exercise-library
        @listLessons="pushExercise"
      />
    </b-modal>

    <b-modal
      id="modal-edit"
      v-model="showChapter"
      ok-variant="danger"
      hide-footer
      centered
      size="lg"
      title="Chapter"
    >
      <validation-observer ref="addChapter">
        <b-row>
          <b-col md="5">
            <validation-provider
              #default="{ errors }"
              name="Tên chapter"
              rules="required"
            >
              <b-form-group>
                <b-form-input
                  v-model="frmChapter.name"
                  placeholder="Nhập tên chapter"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>

            </validation-provider>
          </b-col>
          <b-col md="5">
            <validation-provider
              #default="{ errors }"
              name="Thời gian"
              rules="required"
            >
              <b-form-group>
                <cleave
                  id="time"
                  v-model="frmChapter.time"
                  class="form-control"
                  :raw="false"
                  :options="timeOption"
                  placeholder="mm:ss"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col cols="2">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="danger"
              class="btn-sm"
              @click="submitHandleChapter"
            >
              <feather-icon
                icon="SaveIcon"
                class="cursor-pointer"
              />
              {{ this.editChapter ? 'Cập nhật' : 'Lưu' }}
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
      <hr>
      <table
        class="table b-table"
      >
        <thead>
          <tr>
            <th
              scope="col"
              width="10"
            >
              STT
            </th>
            <th
              scope="col"
              width="300"
            >
              {{ $t("name_q") }}
            </th>
            <th
              scope="col"
              width="100"
            >
              Thời gian
            </th>
            <th width="100">
              {{ $t("opiton") }}
            </th>
          </tr>
        </thead>
        <template v-if="chapterData.length">
          <template v-if="isLoading" />
          <tr
            v-for="(item, index) in chapterData"
            :id="item.id"
            :key="item.id"
          >
            <td>
              {{ item.order }}
            </td>
            <td>
              {{ item.name }}
            </td>
            <td>
              {{ item.time }}
            </td>
            <td>
              <b-dropdown
                id="dropdown-1"
                class="custom-position-dropdown"
                variant="flat-sencondary"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreHorizontalIcon"
                    size="16"
                    class="align-middle"
                  />
                </template>
                <div
                  class="dropdown-item"
                  @click="editChapterAction(item)"
                >
                  {{ $t('edit') }}
                </div>
                <div
                  class="dropdown-item"
                  @click="deleteChapter(item.id)"
                >{{ $t('Delete') }}</div>
              </b-dropdown>
            </td>
          </tr>
        </template>
        <tr
          v-else
        >
          <td colspan="8">
            <p class="text-center">
              {{ $t('no_data') }}
            </p>
          </td>
        </tr>
      </table>
    </b-modal>

  </b-overlay>
</template>

<script>
import {
  BListGroup, BDropdown, BDropdownItem, BInputGroupPrepend, BListGroupItem, BRow, BCol, BFormInput, BOverlay, BButton, BForm, BFormCheckbox, BFormGroup, BFormFile, BCardText, BFormTextarea, BImg, BInputGroup,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import {
  ValidationProvider, ValidationObserver,
} from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import uploadMedia from '@/views/library/UploadMedia'
import ExerciseLibrary from '@/views/capacity/ExerciseLibrary'
import 'cleave.js/dist/addons/cleave-phone.us'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    BInputGroup,
    uploadMedia,
    BInputGroupPrepend,
    ExerciseLibrary,
    BListGroup,
    BListGroupItem,
    draggable,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    Cleave,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormFile,
    BCardText,
    BFormTextarea,
    BImg,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    lessonId: {
      type: Number,
      required: true,
    },
    data: {
      type: Array,
    },
  },
  data() {
    return {
      isLoading: false,
      showChapter: false,
      editChapter: false,
      chapterData: [],
      frmChapter: {
        name: null,
        time: null,
      },
      frm: {
        name: null,
        description: null,
        link: null,
        document: {
          type: null,
          link: null,
          file_id: null,
        },
        document_file: null,
        image_id: null,
        is_trial: 0,
        // eslint-disable-next-line global-require
        image: require('@/assets/images/default.jpg'),
      },
      rows: [],
      modalShow: false,
      videoId: false,
      modalShowExercise: false,
      timeOption: {
        time: true,
        timePattern: ['m', 's'],
      },
      modalName: null,
      documentFile: null,
      mediaDomain: 'https://media.crazytoeic.vn/',
      endPoint: process.env.VUE_APP_API_ENDPOINT,
      option: [{ label: 'Gán link', value: 'link' }, { label: 'Tải lên', value: 'upload' }],
    }
  },
  watch: {
    'frm.document.type': {
      handler(newVal) {
        if (newVal === 'link') {
          this.frm.document.file_id = null
        } else if (newVal === 'upload') {
          this.frm.document.link = null
        } else {
          this.frm.document.file_id = this.frm.document.link = null
        }
      },
    },
  },
  created() {
    if (this.data) {
      this.rows = this.data
    }
  },
  methods: {
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    removeDocument() {
      this.frm.document.type = this.frm.document.link = this.frm.document.file_id = null
      this.frm.document_file = null
    },
    loadChapterByLesson(id) {
      this.showChapter = true
      this.videoId = id
      this.$http.get(`chapters/agent/${id}`, {
        params: {
          type: 2,
        },
      })
        .then(res => {
          this.chapterData = res.data.data
        }).catch()
        .finally(() => {
        })
    },

    submitHandleChapter() {
      this.$refs.addChapter.validate().then(success => {
        if (success) {
          this.isLoading = true
          const formData = new FormData()

          formData.append('name', this.frmChapter.name)
          formData.append('time', this.frmChapter.time)
          formData.append('agent_id', this.videoId)
          formData.append('type', 2)
          if (!this.editChapter) {
            formData.append('order', this.chapterData.length + 1)
          }

          const url = this.editChapter ? `/chapters/${this.editChapter}` : '/chapters'
          if (this.editChapter) formData.append('_method', 'PUT')

          this.$http({
            method: 'post',
            url,
            data: formData,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })

          // eslint-disable-next-line no-unused-vars
            .then(res => {
              this.loadChapterByLesson(this.videoId)
              this.frmChapter.name = this.frmChapter.time = null
              this.editChapter = false

              this.$bvToast.toast(this.$t('message.update_success'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'success',
                solid: false,
              })
            })
          // eslint-disable-next-line no-unused-vars
            .catch(error => {
              this.$bvToast.toast(this.$t('message.update_err'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'danger',
                solid: false,
              })
            }).finally(() => {
              this.isLoading = false
            })
        }
      })
    },
    editChapterAction(item) {
      this.frmChapter.name = item.name
      this.frmChapter.time = item.time
      this.editChapter = item.id
    },
    deleteChapter(id) {
      this.$swal({
        title: this.$t('message.Confirm_delete'),
        text: false,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isLoading = true
          this.$http.delete(`/chapters/${id}`)
          // eslint-disable-next-line no-unused-vars
            .then(res => {
              this.$bvToast.toast(this.$t('message.delete_success'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'success',
                solid: false,
              })
              this.loadChapterByLesson(this.videoId)
              // eslint-disable-next-line no-unused-vars
            }).catch(e => {
              this.$bvToast.toast(this.$t('message.delete_err'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'danger',
                solid: false,
              })
            }).finally(() => {
              this.isLoading = false
            })
        }
      })
    },

    handleSubmit() {
      this.$refs.formRules.validate().then(success => {
        if (success) {
          this.isLoading = true
          const formData = new FormData()

          formData.append('name', this.frm.name)
          if (this.frm.description) formData.append('description', this.frm.description)
          formData.append('link', this.mediaDomain + this.frm.link)
          formData.append('document', JSON.stringify(this.frm.document))
          formData.append('status', 1)
          formData.append('type', 2)
          if (this.frm.image_id) formData.append('image_id', this.frm.image_id)
          formData.append('is_trial', JSON.stringify(this.frm.is_trial))
          formData.append('topic_id', this.lessonId)

          if (this.frm.id) formData.append('_method', 'PUT')

          const url = this.frm.id ? `/lessons/${this.frm.id}` : '/lessons'
          this.$http({
            method: 'post',
            url,
            data: formData,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })

          // eslint-disable-next-line no-unused-vars
            .then(res => {
              this.loadLessons()
              this.modalShow = false
              this.clearModal()
              this.showToast('success', this.$t('message.update_success'))
            })
          // eslint-disable-next-line no-unused-vars
            .catch(error => {
              console.log(error)
              this.showToast('danger', 'Cập nhật thất bại')
            }).finally(() => {
              this.isLoading = false
            })
        }
      })
    },
    clearModal() {
      this.frm.id = null
      this.frm.name = this.frm.description = null
      this.frm.link = this.frm.document.type = null
      this.frm.document.file_id = this.frm.document.link = null
      this.frm.document_file = this.frm.image_id = null
      this.frm.is_trial = 0
      this.frm.image = require('@/assets/images/default.jpg')
    },
    editVideo(item) {
      this.modalShow = true
      this.frm.lesson_id = this.lessonId
      if (!item) {
        this.modalName = this.$t('add_video')
        this.clearModal()
      } else {
        this.frm.id = item.id
        this.frm.name = item.name
        this.frm.description = item.description
        this.frm.link = item.link.replace(this.mediaDomain, '')
        // eslint-disable-next-line radix
        this.frm.is_trial = parseInt(item.is_trial) === 1
        this.frm.document.type = item.document_type
        if (item.image) {
          this.frm.image_id = item.image.id
          this.frm.image = `${this.endPoint}/storage${item.image.url}`
        } else {
          this.frm.image = require('@/assets/images/default.jpg')
        }
        if (item.document_type === 'upload') {
          this.frm.document_file = item.document
          this.frm.document.file_id = item.document_id
        } else if (item.document_type === 'link') {
          this.frm.document.link = item.document.name
        } else {
          // eslint-disable-next-line no-multi-assign
          this.frm.document_file = this.frm.document.file_id = null
          this.frm.document.link = null
        }
        // if (item.document_file) this.documentFile = `${this.endPoint}/storage${item.document_file}`
        this.modalName = this.$t('edit_video_lessons')
      }
    },
    editExercise() {
      this.modalShowExercise = true
    },
    onEnd(value) {
      if (value.oldIndex === value.newIndex) return
      const newOrder = this.rows[value.newIndex].pivot.order
      const oldOrder = this.rows[value.oldIndex].pivot.order
      console.log(newOrder, oldOrder)
      if (newOrder === oldOrder) return
      if (value.newIndex < value.oldIndex) {
        this.changeOrder(newOrder, this.rows[value.newIndex + 1].pivot.order)
      } else {
        this.changeOrder(newOrder, this.rows[value.newIndex - 1].pivot.order)
      }
    },
    changeOrder(oldOrder, newOrder) {
      this.isLoading = true
      this.$http.put('topics/lessons/change-order', { oldOrder, newOrder, topic_id: this.lessonId })
      // eslint-disable-next-line no-unused-vars
        .then(res => {
          this.loadLessons()
          this.showToast('success', 'Cập nhật thứ tự thành công')
          // eslint-disable-next-line no-unused-vars
        }).catch(e => {
          this.showToast('danger', 'Cập nhật thứ tự thất bại')
        }).finally(() => {
          this.isLoading = false
        })
    },
    showToast(variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Thông báo',
          icon: 'BellIcon',
          text,
          variant,
        },
      })
    },
    loadLessons() {
      this.isLoading = true
      this.$http.get(`/topics/lessons/${this.lessonId}`)
        .then(res => {
          this.rows = res.data
        }).catch()
        .finally(() => {
          this.isLoading = false
        })
    },
    uploadMedia(media) {
      if (media.image) {
        this.frm.image_id = media.image.id
        this.frm.image = `${this.endPoint}/storage${media.image.url}`
      }
      if (media.document) {
        this.frm.document.file_id = media.document.id
        this.frm.document_file = media.document
      }
    },
    pushExercise(listExc) {
      if (listExc.length) {
        const ids = []
        listExc.map(v => {
          ids.push(v.id)
        })
        const formData = new FormData()

        formData.append('exericse_ids', JSON.stringify(ids))
        formData.append('topic_id', this.lessonId)
        formData.append('_method', 'PUT')

        const url = '/lessons/add-topic'
        this.$http({
          method: 'post',
          url,
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        // eslint-disable-next-line no-unused-vars
          .then(res => {
            this.loadLessons()
            this.modalShowExercise = false
            this.showToast('success', 'Thêm bài học thành công')
          })
          .catch(error => {
            this.showToast('danger', 'Thêm bài học thất bại')
          }).finally(() => {
          })
      }
    },
    remove(id, index) {
      this.$swal({
        title: 'Bạn có chắc không ?',
        text: 'Bạn sẽ không thể lấy lại dữ liệu đã xóa',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Chắc chắn !',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isLoading = true
          this.$http.delete(`/lessons/${id}`)
          // eslint-disable-next-line no-unused-vars
            .then(res => {
              this.showToast('success', 'Xoá bài học thành công')
              this.rows.splice(index, 1)
              // eslint-disable-next-line no-unused-vars
            }).catch(e => {
              this.showToast('danger', 'Xoá bài học thất bại')
            }).finally(() => {
              this.isLoading = false
            })
        }
      })
    },
    deleteLesson(id, index) {
      this.$swal({
        title: 'Bạn có chắc không ?',
        text: 'Bạn sẽ không thể lấy lại dữ liệu đã xóa',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Chắc chắn !',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.delete(`topics/${this.lessonId}/lessons/delete/${id}`)
            .then(res => {
              this.showToast('success', 'Xoá bài học thành công')
              this.rows.splice(index, 1)
              // eslint-disable-next-line no-unused-vars
            }).catch(e => {
              this.showToast('danger', 'Xoá bài học thất bại')
            }).finally(() => {
            })
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.group_file_upload{
  min-height: 218px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d8d6de;
}
.library-document{
  justify-content: center;
  display: inline-flex;
  align-items: center;
  background: #fd7e1e;
  padding: 10px 10px;
  margin: 0 !important;
  font-size: 13px;
  border-radius: 8px;
  color: #fff;
  min-width: 120px;
}
</style>
