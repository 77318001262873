<template>
  <b-overlay
    :show="isLoading"
    rounded="sm"
    spinner-variant="primary"
    spinner-type="grow"
  >
    <b-row>
      <b-col md="4">
        <label for="">
          {{ $t("Search") }}
        </label>
        <b-form-input
          v-model="filter.key"
          :placeholder="$t('search_key')"
        />
      </b-col>
      <b-col md="4">
        <label>
          {{ $t('level') }}
        </label>
        <v-select
          id="level"
          v-model="filter.level"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="name"
          :options="levelData"
          :reduce="label => label.id"
          placeholder="Chọn cấp độ"
        />
      </b-col>
      <b-col md="4">
        <label>
          {{ $t('type') }}
        </label>
        <v-select
          id="type"
          v-model="filter.type"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="name"
          :options="typeData"
          :reduce="label => label.id"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        class="mt-2"
      >
        <b-list-group>
          <b-list-group-item
            v-for="(listItem, index) in listExercises"
            :key="index"
            tag="li"
          >

            <div class="d-flex library-question justify-content-md-start align-items-center">
              <b-form-checkbox
                v-model="chooseListExercise"
                :value="listItem"
                class="custom-control-danger"
                name="check-button"
              />
              <b-card-text
                class="mb-0 ml-1"
                v-html="listItem.name"
              />
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
    <div class="d-flex justify-content-end mt-2">
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="danger"
        class="btn-sm"
        @click="listLessons"
      >
        <feather-icon
          size="15"
          icon="PlusCircleIcon"
        />
        Áp dụng
      </b-button>
    </div>
  </b-overlay>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import BCardCode from '@core/components/b-card-code/BCardCode'
import {
  BCardText,
  BAvatar,
  BBadge,
  BButton,
  BCol, BDropdown, BDropdownItem,
  BEmbed,
  BForm, BFormCheckbox,
  BFormFile,
  BFormGroup,
  BFormInput, BFormRadio,
  BFormSelect, BFormTextarea,
  BImg, BInputGroupAppend, BListGroup, BListGroupItem, BModal,
  BOverlay, BPagination,
  BRow, VBModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import draggable from 'vuedraggable'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    Prism,
    BCardText,
    BImg,
    vSelect,
    BAvatar,
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    quillEditor,
    BCol,
    draggable,
    BFormSelect,
    BEmbed,
    BFormFile,
    BFormGroup,
    BForm,
    BButton,
    BFormInput,
    BOverlay,
    BPagination,
    BFormCheckbox,
    BFormRadio,
    BInputGroupAppend,
    BDropdown,
    // eslint-disable-next-line vue/no-unused-components
    BDropdownItem,
    BListGroup,
    BListGroupItem,
    // eslint-disable-next-line vue/no-unused-components
    BModal,
    BBadge,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      cbxAll: false,
      point: 0,
      modalShow: false,
      currentPage: 1,
      pageLength: 10,
      isLoading: false,
      total: 0,
      ids: [],
      modalName: '',
      snowOption: {
        theme: 'snow',
        placeholder: this.$t('enter_q'),
      },
      endPoint: process.env.VUE_APP_API_ENDPOINT,
      filter: {
        key: '',
        level: null,
        type: null,
      },
      sort: {
        by: 'order',
        direction: 'desc',
      },
      tagOptions: [],
      levelData: [],
      listExercises: [],
      chooseListExercise: [],
      typeData: [
        {
          name: 'Bài tập',
          id: 1,
        },
        {
          name: 'Kiểm tra trình độ',
          id: 4,
        },
      ],
    }
  },
  watch: {
    'filter.level': {
      handler() {
        this.loadExercises()
      },
    },
    'filter.type': {
      handler() {
        this.loadExercises()
      },
    },
  },
  created() {
    this.loadLevels()
  },
  methods: {
    loadLevels() {
      this.isLoading = true
      this.$http.get('levels')
        .then(res => {
          this.levelData = res.data.data
        }).catch()
        .finally(() => {
          this.isLoading = false
        })
    },
    loadExercises() {
      if (this.filter.key === '' && this.filter.level === null && this.filter.type === null) {
        this.listExercises = []
      } else {
        this.isLoading = true
        this.$http.get('/lessons/libraryExercise', {
          params: {
            key: this.filter.key,
            level: this.filter.level,
            type_exercise: this.filter.type,
          },
        })
          .then(res => {
            this.listExercises = res.data.data
          }).catch()
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    listLessons() {
      this.$emit('listLessons', this.chooseListExercise)
    },
  },

}
</script>
<style lang="scss" scoped>
.question-part{
  max-height: 685px;
  overflow-y: scroll;
}
</style>
