<template>
  <b-overlay
    :show="isLoading"
    :opacity="0.6"
    spinner-variant="primary"
    spinner-type="grow"
    rounded="sm"
  >
    <div
      class="item_content_row"
      :class=" item.toggle ? 'active' : ''"
    >
      <div class="item_content_row__item">
        <validation-observer
          ref="updateLessonName"
          style="width: 85%;"
        >
          <template v-if="!item.edit">
            <div
              class="heading_name item_content_row__heading"
              @click="item.toggle = !item.toggle"
            >
              {{ item.name }}
            </div>
          </template>
          <template v-else>
            <validation-provider
              #default="{ errors }"
              name="tên bài học"
              vid="name"
              rules="required"
              mode="passive"
            >
              <div class="heading_name item_content_row__heading">
                <b-form-input
                  v-model="item.newName"
                  style="border: none"
                  type="text"
                  required
                  :state="errors.length > 0 ? false:null"
                  @keydown.enter.native="handleUpdate(item)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </div>

            </validation-provider>

          </template>
        </validation-observer>

        <div class="item_content_row__control">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-secondary"
            class="btn-icon rounded-circle float-right"
            style="margin-left: 5px"
            @click="deleteTopic(item.id, index)"
          >
            <feather-icon icon="Trash2Icon" />
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-secondary"
            class="btn-icon rounded-circle float-right"
            style="margin-left: 5px"
            @click="toggleEdit"
          >
            <feather-icon icon="Edit2Icon" />
          </b-button>
          <b-dropdown
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-secondary"
            class="rounded-circle float-right button-add"
            dropleft
          >
            <template #button-content>
              <feather-icon icon="PlusIcon" />
            </template>
            <b-dropdown-item
              @click="editVideo()"
            >
              <feather-icon icon="VideoIcon" />
              {{ $t('add_video') }}
            </b-dropdown-item>
            <b-dropdown-item
              @click="editExercise()"
            >
              <feather-icon icon="BookIcon" />
              {{ $t('add_exercise') }}
            </b-dropdown-item>
            <b-dropdown-item>
              <feather-icon icon="FlagIcon" />
              {{ $t('add_fl') }}
            </b-dropdown-item>
          </b-dropdown>

          <b-button
            class="button_open_exercise"
            variant="outline-secondary"
            @click="item.toggle = !item.toggle"
          >
            <feather-icon :icon="item.toggle ? 'ChevronDownIcon' : 'ChevronRightIcon'" />
          </b-button>
        </div>
      </div>
    </div>
    <lesson-many
      v-show="item.toggle"
      ref="lessonForm"
      :data="item.lessons"
      :lesson-id="item.id"
    />
  </b-overlay>
</template>

<script>
import {
  BRow, BCol, BFormInput, BOverlay, BButton, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import {
  ValidationProvider, ValidationObserver,
} from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import LessonMany from '@/views/courses/LessonMany'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BDropdown,
    BDropdownItem,
    // eslint-disable-next-line vue/no-unused-components
    LessonMany,
    // LessonExercise,
  },
  directives: {
    Ripple,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    courseId: {
      type: Number,
      required: true,
    },
    courseName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      frm: {
        name: null,
      },
      rows: [],
      modalVideoShow: false,
      frmVideo: {},
      modalVideoName: null,
      imgUrl: null,
      endPoint: process.env.VUE_APP_API_ENDPOINT,
    }
  },
  created() {

  },
  methods: {
    editVideo() {
      this.$refs.lessonForm.editVideo()
    },
    editExercise() {
      this.$refs.lessonForm.editExercise()
    },
    onEnd(value) {
      if (value.oldIndex === value.newIndex) return
      const newOrder = this.rows[value.newIndex].order
      const oldOrder = this.rows[value.oldIndex].order
      if (newOrder === oldOrder) return
      console.log(value)
      if (value.newIndex < value.oldIndex) {
        this.changeOrder(newOrder, this.rows[value.newIndex + 1].order)
      } else {
        this.changeOrder(newOrder, this.rows[value.newIndex - 1].order)
      }
    },
    changeOrder(oldOrder, newOrder) {
      this.isLoading = true
      // console.log(oldOrder, newOrder)
      // this.$http.put('/videos', { oldOrder, newOrder })
      // eslint-disable-next-line no-unused-vars
      //   .then(res => {
      //     this.showToast('success', 'Cập nhật thứ tự thành công')
      //     // eslint-disable-next-line no-unused-vars
      //   }).catch(e => {
      //     this.showToast('danger', 'Cập nhật thứ tự thất bại')
      //   }).finally(() => {
      //     this.isLoading = false
      //     this.loadItems()
      //   })
    },
    showToast(variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t('message.notify'),
          icon: 'BellIcon',
          text,
          variant,
        },
      })
    },
    deleteTopic(id, index) {
      this.$swal({
        title: this.$t('title_confirm_delete'),
        text: this.$t('text_confirm_delete'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'OK !',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isLoading = true
          this.$http.delete(`/topics/${id}`)
            .then(res => {
              this.showToast('success', this.$t('message.delete_success'))
              this.$emit('deleteLesson', index)
            }).catch(e => {
              this.showToast('danger', this.$t('message.delete_err'))
            }).finally(() => {
              this.isLoading = false
            })
        }
      })
    },
    toggleEdit() {
      this.item.edit = !this.item.edit
      this.item.newName = this.item.name
    },
    handleUpdate(item) {
      this.$refs.updateLessonName.validate().then(success => {
        if (success) {
          this.isLoading = true
          this.$http({
            method: 'put',
            url: `/topics/${item.id}`,
            data: {
              name: item.newName,
            },
          }).then(res => {
            this.showToast('success', this.$t('message.update_success'))
            item.name = item.newName
          })
            .catch(e => {
              this.showToast('danger', this.$t('message.update_err'))
              item.newName = item.name
            }).finally(() => {
              this.isLoading = false
              item.edit = false
            })
        }
      })
    },
  },
}
</script>
