<template>
  <b-row>
    <b-col>
      <validation-observer ref="simpleRules">
        <div class="form-label-group">
          <validation-provider
            #default="{ errors }"
            name="tên bài học"
            vid="name"
            rules="required"
            mode="passive"
          >
            <b-form-input
              id="frm.name"
              v-model="frm.name"
              style="border: none"
              type="text"
              :placeholder="$t('add_topic_new')"
              :state="errors.length > 0 ? false:null"
              @keydown.enter.native="validationForm"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </div>
      </validation-observer>
      <b-skeleton-wrapper
        v-if="!rows.length"
        :loading="true"
      >
        <template #loading>
          <b-card>
            <b-skeleton width="85%" />
            <b-skeleton width="55%" />
            <b-skeleton width="70%" />
          </b-card>
        </template>
      </b-skeleton-wrapper>

      <b-list-group
        v-else
        class="list_group_custom"
      >
        <draggable
          v-model="rows"
          tag="tbody"
          @end="value => onEnd(value)"
        >
          <b-list-group-item
            v-for="(item, index) in rows"
            :id="item.id"
            :key="item.id"
            class="align-items-center"
          >
            <lesson-detail
              :item="item"
              :index="index"
              :course-id="courseId"
              :course-name="courseName"
              @deleteLesson="deleteLesson"
              @addLesson="addLesson"
            />
          </b-list-group-item>
        </draggable>
      </b-list-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  BListGroup, BCard, BListGroupItem, BRow, BCol, BFormInput, BOverlay, BSkeleton, BSkeletonWrapper,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import {
  ValidationProvider, ValidationObserver,
} from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import lessonDetail from '@/views/courses/LessonDetail'

export default {
  components: {
    BSkeleton,
    BCard,
    BSkeletonWrapper,
    BListGroup,
    BListGroupItem,
    draggable,
    BRow,
    BCol,
    lessonDetail,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  props: {
    courseId: {
      type: Number,
      required: true,
    },
    courseName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      frm: {
        name: null,
      },
      rows: [],
      modalVideoShow: false,
      modalVideoName: null,
      imgUrl: null,
      endPoint: process.env.VUE_APP_API_ENDPOINT,
    }
  },
  created() {
    this.loadItems()
  },
  methods: {
    onEnd(value) {
      if (value.oldIndex === value.newIndex) return
      const newOrder = this.rows[value.newIndex].order
      const oldOrder = this.rows[value.oldIndex].order
      if (newOrder === oldOrder) return
      if (value.newIndex < value.oldIndex) {
        this.changeOrder(newOrder, this.rows[value.newIndex + 1].order)
      } else {
        this.changeOrder(newOrder, this.rows[value.newIndex - 1].order)
      }
    },
    changeOrder(oldOrder, newOrder) {
      this.isLoading = true
      this.$http.put('/topics', { oldOrder, newOrder, courseId: this.courseId })
      // eslint-disable-next-line no-unused-vars
        .then(res => {
          this.showToast('success', this.$t('message.update_success'))
          // eslint-disable-next-line no-unused-vars
        }).catch(e => {
          this.showToast('danger', this.$t('message.update_err'))
        }).finally(() => {
          this.isLoading = false
          this.loadItems()
        })
    },
    showToast(variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t('message.notify'),
          icon: 'BellIcon',
          text,
          variant,
        },
      })
    },
    loadItems() {
      const params = {
        perPage: this.pageLength,
        page: this.currentPage,
      }

      params.course_id = this.courseId

      this.isLoading = true
      this.$http.get('/topics', {
        params,
      })
        .then(res => {
          this.rows = res.data.data.map(v => {
            v.edit = false
            v.newName = v.name
            v.toggle = false
            return v
          })
        }).catch()
        .finally(() => {
          this.isLoading = false
        })
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.isLoading = true
          this.$http({
            method: 'post',
            url: '/topics',
            data: {
              name: this.frm.name,
              course_id: this.courseId,
            },
          })

          // eslint-disable-next-line no-unused-vars
            .then(res => {
              this.showToast('success', this.$t('message.update_success'))

              const { data } = res
              this.frm.name = null
              this.rows.push(data)
            })
          // eslint-disable-next-line no-unused-vars
            .catch(e => {
              this.showToast('danger', this.$t('message.update_err'))
            }).finally(() => {
              this.isLoading = false
            })
        }
      })
    },
    deleteLesson(index) {
      this.rows.splice(index, 1)
    },
    addLesson(data) {
      this.rows.push(data)
    },
  },
}
</script>
